import React from "react"
import CKEditor from "@ckeditor/ckeditor5-react";
// NOTE: Use the editor from source (not a build)!
import BalloonEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';

import './RichTextEditor.css';

const editorConfiguration = {
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    BlockQuotePlugin,
    HeadingPlugin,
    ListPlugin,
    ParagraphPlugin,
    EasyImage,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    Base64UploadAdapter,
    CodeBlock
  ],
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'codeBlock',
      'blockQuote',
      'undo',
      'redo'
    ]
  },
  image: {
    toolbar: [
      'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageTextAlternative'
    ]
  },
  // This value must be kept in sync with the language defined in webpack.config.js.
  language: 'en'

};

const editorConfigurationLimited = {
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    BlockQuotePlugin,
    HeadingPlugin,
    ListPlugin,
    ParagraphPlugin
  ],
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo'
    ]
  },
  // This value must be kept in sync with the language defined in webpack.config.js.
  language: 'en'
};

function RichTextEditor({ input, isRequired, meta: {error, touched}, label, limitedConfiguration }) {
  const handleOnChange = (event, editor) => {
    const data = editor.getData();
    input.onChange(data);
  };
  const classNames = 'field ' + (isRequired ? 'required ' : '') + (error ? 'error' : '');
  return (
    <div className={classNames}>
      <label>{label}</label>
      <div className="ui input">
        <CKEditor
          className={touched && error ? 'required' : ''}
          editor={BalloonEditor}
          config={limitedConfiguration ? editorConfigurationLimited : editorConfiguration}
          data={input.value}
          onChange={handleOnChange}
          onBlur={handleOnChange}
        />
      </div>
      {touched && error && <div className="richTextBoxRequired">{error}</div> }
    </div>

  )
}

export default RichTextEditor;
