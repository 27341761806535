import { UI_ACTIONS } from "../actions/commonUIAction";

const initialAppState = {
  showLoader: false
};

const commonUIReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case UI_ACTIONS.SHOW_LOADER:
      return { ...state, showLoader: true };
    case UI_ACTIONS.HIDE_LOADER:
      return { ...state, showLoader: false };
    default:
      return state;
  }
};
export default commonUIReducer;
