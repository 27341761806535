export class sessionStore {

  /**
   * @method set
   * @description function to set the data in sessionStorage
   */
  static set(key, value) {
    sessionStorage.setItem(key, value);
  }

  /**
   * @method get
   * @description function to get the item from the sessionStorage
   */
  static get(key) {
    return sessionStorage.getItem(key);
  }

  /**
   * @method getJSON
   * @description function to get the json value from the sessionStorage
   */
  static getJSON(key) {
    let returnValue = {};
    try {
      returnValue = JSON.parse(sessionStorage.getItem(key));
    } catch (e) {}
    return returnValue || {};
  }

  /**
   * @method getJSON
   * @description function to get the json value from the sessionStorage
   */
  static setJSON(key, data) {
    const jsonStr = JSON.stringify(data);
    return sessionStorage.setItem(key, jsonStr);
  }

  /**
   * @method remove
   * @description function to remove the sessionStorage item
   */
  static remove(key) {
    return sessionStorage.removeItem(key);
  }

  /**
   * @method clear
   * @description function to clear sessionStorage
   */
  static clear() {
    return sessionStorage.clear();
  }
}

export default sessionStore
