import { EXAM_BOARD_ACTIONS } from "../actions/examBoardAction";
import {sessionStore} from "../../../shared";

const initialAppState = null;

const examBoardReducer = (state = initialAppState, action) => {
  let newState;

  switch (action.type) {

    case EXAM_BOARD_ACTIONS.LOAD_EXAM_BOARD_DATA:
      return {...state, ...action.payload};

    case EXAM_BOARD_ACTIONS.LOAD_QUESTION_DATA_BY_INDEX:
      newState = { ...state };
      if (typeof action.payload.index !== 'number') {
        newState['Questions'][0]['ActiveQuestion'] = true;
      } else {
        newState['Questions'].forEach((question, index) => {
          if (question.ActiveQuestion === true) {
            question.ActiveQuestion = false;
          } else if (!question.ActiveQuestion && index === action.payload.index) {
            question.ActiveQuestion = true;
            if (typeof action.payload.oldIndex === 'number' && state['Questions'][action.payload.oldIndex]['Answer'].length <= 0) {
              if (newState['Questions'][action.payload.oldIndex]['Status'] !== 'Skipped') {
                newState['Questions'][action.payload.oldIndex]['Status'] = 'Skipped';
                newState['TotalSkipped']++;
              }
            }
          }
        });
      }
      return { ...newState };

    case EXAM_BOARD_ACTIONS.SET_QUESTION_STATUS:
      newState = {...state};
      if (action.payload.IsMarked && !state['Questions'][action.payload.index]['IsMarked']) {
        newState['Questions'][action.payload.index]['IsMarked'] = true;
        newState['TotalMarked']++;
      } else if (newState['Questions'][action.payload.index]['IsMarked'] && !action.payload.IsMarked) {
        newState['Questions'][action.payload.index]['IsMarked'] = false;
        newState['TotalMarked']--;
      }
      return { ...newState };

    case EXAM_BOARD_ACTIONS.SET_ANSWER_QUESTION:
      newState = {...state};
      const { index, id } = action.payload;
      if (newState.Questions[index]['Answer'].includes(id)) {
        newState.Questions[index]['Answer'].splice(newState.Questions[index]['Answer'].indexOf(id), 1 );
      } else {
        newState.Questions[index]['Answer'].push(id);
      }
      if (newState.Questions[index]['Answer'].length > 0) {
        (newState.Questions[index]['Status'] === 'Skipped') && newState['TotalSkipped']--;
        if (newState.Questions[index]['Status'] !== 'Attempted') {
          newState.Questions[index]['Status'] = 'Attempted';
          newState['TotalAttempted']++;
        }
      } else {
        (newState.Questions[index]['Status'] === 'Attempted') && newState['TotalAttempted']--;
        if (newState.Questions[index]['Status'] !== 'Skipped') {
          newState.Questions[index]['Status'] = 'Skipped';
          newState['TotalSkipped']++;
        }
        if (newState.Questions[index]['IsMarked']) {
          newState.Questions[index]['IsMarked'] = false;
          newState['TotalMarked']--;
        }
      }
      return { ...newState };

    case EXAM_BOARD_ACTIONS.REMOVE_EXAM_DATA:
      sessionStore.remove('examData');
      sessionStore.remove('examRemainingTime');
      sessionStore.remove('collegeData');
      return null;

    default:
      return state;
  }
};

export default examBoardReducer;
