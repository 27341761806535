import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import { Button, Modal, Image, Checkbox } from 'semantic-ui-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { rapidHttpClient } from '../../../../shared/rapidHTTPClient';
import ActionRenderer from './ActionRenderer';
import deleteImage from '../../../../semantic-ui/site/assets/images/Delete-Graphic.svg';
import { AG_GRID } from '../../constant';
import { questionSummary } from '../../../redux/actions/questionSummaryAction';
import RapidGridPagination from './RapidGridPagination';
import { setPagination, setSelectedRowData, removeRapidGridData } from '../../../redux/actions/rapidGridAction';
import './RapidGrid.less';
import CustomTooltip from './CustomTooltip';

LicenseManager.setLicenseKey('RapidOps_Inc._MultiApp_2Devs_1Deployment_1_July_2020__MTU5MzU1ODAwMDAwMA==eec5f4ee24e6046c529f18fdc33bc0ce');

class RapidGrid extends PureComponent {
  footerHeight = 100;
  defaultQueryParams = {};
  extraQueryParams = {};
  timeIntervalVal;
  gridRef;
  state = {
    isDeleteModelOpen: false,
    context: { componentParent: this },
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>'
  };

  constructor(props) {
    super(props);
    this.entityDeleteId = null;
    this.__setColumnData();
    this.__setGridOptions();
    this.frameworkComponents = {
      actionRenderer: ActionRenderer,
      customTooltip: CustomTooltip
    };
    this.actionArray = [];
    this.props.actions && this.props.actions.map(async (action) => this.actionArray.push(this.__setActionObject(action)));
    if (props.gridRef) {
      props.gridRef(this);
    }
    this.gridRef = React.createRef();
  }

  updateDimensions = () => {
    const agGridHeader = this.gridRef.current.querySelector('.ag-header');
    if(this.props.height){
      this.gridRef.current.style.height = this.props.height;
    } else {
      this.gridRef.current.style.height = window.innerHeight - (agGridHeader.getBoundingClientRect().top + this.footerHeight) + 'px';
    }
  };

  componentDidMount() {
    this.__setColumnData();
    this.__setGridOptions();
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.props.removeRapidGridData(this.props.gridId);
    window.removeEventListener('resize', this.updateDimensions);
    window.clearInterval(this.timeIntervalVal);
  }

  __setColumnData() {
    this.__setColumnFilterOptions();
  }

  __setColumnFilterOptions() {
    this.props.columnDefs.forEach((column) => {
      column['menuTabs'] = ['filterMenuTab'];
      if (column.filter === 'agTextColumnFilter') {
        column.filterParams = {
          filterOptions: [],
          suppressAndOrCondition: true,
          applyButton: true,
          resetButton: true
        };
      }
      return column;
    });
  }

  __setSelectedRow(params) {
    return (
      <Checkbox
        id='HeaderCheckBox'
        onChange={(event, data) => {
          params.api.forEachNode(row => params.api.getRowNode(row.id).selectThisNode(data.checked));
          this.__onSelectionChanged();
        }}
      />
    );
  };

  __getGridColumns() {
    const columnDefs = [...this.props.columnDefs];
    if (this.props.hasOwnProperty('actions') && this.props.actions.length) {
      columnDefs.push({
        menuTabs: [],
        headerName: 'Action',
        cellRenderer: 'actionRenderer',
        width: 150,
        sortable: false,
      });
    }
    if (this.props.hasOwnProperty('hasCheckBox') && this.props.hasCheckBox) {
      columnDefs.unshift({
        colId: 'checkBox',
        field: '',
        headerName: '',
        suppressMenu: true,
        suppressSizeToFit: true,
        checkboxSelection: true,
        width: 40,
        sortable: false,
      });
      if (this.props.showSelectAll) columnDefs[0]['headerComponentFramework'] = params => this.__setSelectedRow(params);
    }
    return columnDefs;
  }

  async onActionClick(action, cell) {
    const entityId = action.data._id;
    switch (cell) {
      case 'copy':
        await this.copyEntity(entityId, true);
        break;
      case 'edit':
        await this.editEntity(entityId);
        break;
      case 'delete':
        this.entityDeleteId = entityId;
        await this.beforeDeleteEntity(entityId);
        break;
      case 'swap':
        await this.swapEntity(action.data, Number(action.id) + 1);
        break;
      case 'view':
        await this.viewEntity(entityId);
        break;
      case 'viewQuestion':
        await this.viewQuestionEntity(entityId);
        break;
      default:
        break;
    }
  }

  __setGridOptions() {
    this.gridOptions = {
      rowHeight: 48,
      headerHeight: 48,
      icons: 'icons',
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        suppressMovable: true
      },
      pagination: true,
      suppressRowClickSelection: true,
      rowSelection: this.props.isNotMultipleSelect ? 'single' : 'multiple',
      rowMultiSelectWithClick: true,
      paginationPageSize: AG_GRID.PAGE_SIZE,
      rowModelType: 'infinite',
      cacheBlockSize: AG_GRID.PAGE_SIZE,
      maxBlocksInCache: 1
      // onGridReady: (params) => {
      //   params.api.sizeColumnsToFit();
      //   this.gridApi = params.api;
      //   this.gridColumnApi = params.columnApi;
      // }
    };
  }

  __convertAgGridFilterModelToFilterObject(filterModelObject) {
    const filterObject = {};
    const filterParamsKeys = Object.keys(filterModelObject);
    if (filterParamsKeys.length) {
      for (const key of filterParamsKeys) {
        if (filterModelObject[key]) {
           filterObject[key] = filterModelObject[key].filterType === 'set' ? filterModelObject[key].values : filterModelObject[key].filter;
        }
      }
    }
    return filterObject;
  }

  __getRows = async ({ startRow, endRow, sortModel, filterModel, successCallback, failCallback }) => {
    let searchQuery = {
      sortBy: sortModel.length ? sortModel[0].colId : undefined,
      sortOrder: sortModel.length ? sortModel[0].sort : undefined,
      pageNo: Math.ceil(endRow / AG_GRID.PAGE_SIZE),
      rows: AG_GRID.PAGE_SIZE
    };

    if (filterModel && Object.keys(filterModel).length) {
      // prepare filter query object from grid filtermodel
      const columnFilterQuery = this.__convertAgGridFilterModelToFilterObject(filterModel);
      searchQuery = { ...searchQuery, ...columnFilterQuery };
    }

    if (this.props.defaultQueryParams && Object.keys(this.props.defaultQueryParams).length) {
      searchQuery = { ...searchQuery, ...this.props.defaultQueryParams };
    }

    if (this.extraQueryParams && Object.keys(this.extraQueryParams).length) {
      searchQuery = { ...searchQuery, ...this.extraQueryParams };
    }
    this.props.setSelectedRowData(this.props.gridId, []);
    const headerCheckbox = document.getElementById('HeaderCheckBox');
    if (headerCheckbox) {
      headerCheckbox.checked = false;
    }
    try {
      this.gridApi.showLoadingOverlay();
      const rowData = (await rapidHttpClient.callAPI({
        url: this.props.api,
        useLoader: false,
        query: searchQuery,
        errorMessage: 'Error in getting the data'
      }));
      if (rowData.totalRows === 0) {
        // show "No data to display" message
        this.gridApi.showNoRowsOverlay();
      } else {
        // hide "No data to display" message
        this.gridApi.hideOverlay();
      }
      successCallback(rowData['data'], rowData.totalRows);
      this.props.setPagination(this.props.gridId, {
        totalPages: Math.ceil(rowData.totalRows / AG_GRID.PAGE_SIZE),
        activePage: this.gridApi.paginationGetCurrentPage() + 1
      });
      this.gridApi.sizeColumnsToFit();
    } catch (e) {
      failCallback();
      this.gridApi.hideOverlay();
    }
  };

  reloadGrid = queryParams => {
    if (this.gridApi) {
      this.extraQueryParams = queryParams || this.extraQueryParams;
      this.gridApi.refreshInfiniteCache();
      this.gridApi.forEachNode(row => this.gridApi.getRowNode(row.id).selectThisNode(false));
      this.gridApi.refreshHeader();
    }
    this.props.setSelectedRowData(this.props.gridId, []);
  };

  async editEntity(entityId) {
    this.props.onEdit(entityId);
  }

  async copyEntity(entityId, isCopy) {
    this.props.onCopy(entityId, isCopy);
  }

  async viewEntity(entityId) {
    this.props.onView(entityId);
  }

  async swapEntity(entityId, objectIndex) {
    this.props.onSwap(entityId, objectIndex);
  }

  async viewQuestionEntity(entityId) {
    this.props.onViewQuestion(entityId);
  }

  beforeDeleteEntity() {
    this.setState({ isDeleteModelOpen: true });
  }

  async confirmDelete() {
    this.setState({ isDeleteModelOpen: false });
    await this.deleteEntity();
  }

  async deleteEntity() {
    await rapidHttpClient.callAPI({
      url: `${this.props.api}/${this.entityDeleteId}`,
      method: 'delete',
      successMessage: `${this.props.deleteSuccessMsg}`
    });
    this.reloadGrid();
    if (this.props.onDeleteSuccess) {
      this.props.onDeleteSuccess();
    }
  }

  __onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.setDatasource({
      getRows: this.__getRows
    });
    if (this.props.autoRefresh) {
      this.timeIntervalVal = window.setInterval(() => {
        this.gridApi.setDatasource({
          getRows: this.__getRows
        });
      }, this.props.autoRefresh);
    }
  };

  __onSelectionChanged = () => {
    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.props && this.props.gridId) {
      this.props.setSelectedRowData(this.props.gridId, this.selectedRows);
    }
    let headerCheckBox = document.getElementById("HeaderCheckBox");
    if (this.selectedRows.length === this.gridApi.getDisplayedRowCount()) {
      headerCheckBox.checked = true;
    } else if (headerCheckBox) {
      headerCheckBox.checked = false;
    }
  };

  __setActionObject(action) {
    let actionObject = {};
    switch (action) {
      case 'edit':
        actionObject['name'] = 'Edit';
        actionObject['action'] = 'edit';
        actionObject['icon'] = 'pencil';
        break;
      case 'delete':
        actionObject['name'] = 'Delete';
        actionObject['action'] = 'delete';
        actionObject['icon'] = 'trash';
        break;
      case 'copy':
        actionObject['name'] = 'Copy';
        actionObject['action'] = 'copy';
        actionObject['icon'] = 'copy';
        break;
      case 'swap':
        actionObject['name'] = 'Swap';
        actionObject['action'] = 'swap';
        actionObject['icon'] = 'random';
        break;
      case 'view':
        actionObject['name'] = 'View';
        actionObject['action'] = 'view';
        actionObject['icon'] = 'eye';
        break;
      case 'viewQuestion':
        actionObject['name'] = 'ViewQuestion';
        actionObject['action'] = 'viewQuestion';
        actionObject['icon'] = 'eye';
        break;
      default:
        break;
    }
    return actionObject;
  }

  __onCellClicked = (event) => {
    if (event && event.column && event.column.userProvidedColDef && this.props.cellClickForColumn && this.props.cellClickForColumn.length > 0) {
      this.props.cellClickForColumn.forEach((item) => {
        if (event.column.userProvidedColDef.headerName === item) {
          return this.props.onCellClicked ? this.props.onCellClicked({ [item]: event.data }) : null;
        }
      });
    }
    return this.props.onCellClicked ? this.props.onCellClicked({}) : null;
  };

  __onGridPaginationClick = async (event, cell) => {
    const activePage = cell.activePage;
    this.props.setPagination(this.props.gridId, {
      activePage: activePage,
      totalPages: cell.totalPages
    });
    this.gridApi && this.gridApi.paginationGoToPage(activePage - 1);
  };

  render() {
    const { gridId } = this.props;
    const columns = this.__getGridColumns();
    this.__setColumnFilterOptions();
    return (
      <div id={gridId} className="ag-theme-balham">
        <Modal open={this.state.isDeleteModelOpen} className='tiny text-center'>
          <Modal.Header>
            <button className="CloseButton" onClick={() => this.setState({ isDeleteModelOpen: false })}>
              <i className="icon close tiny"/></button>
          </Modal.Header>
          <Modal.Content>
            <Image src={deleteImage} className="centered aligned mb30"/>
            <p className="bigText plr30">{this.props.deleteConfirmationMsg}</p>
            <Button autoFocus={true} className="primary" onClick={this.confirmDelete.bind(this)}>Yes</Button>
          </Modal.Content>
        </Modal>
        <div ref={this.gridRef} className={this.props.classNames}>
          <AgGridReact
            {...this.props}
            actions={this.actionArray}
            gridOptions={this.gridOptions}
            columnDefs={columns}
            context={this.state.context}
            onGridReady={this.__onGridReady}
            rowSelection={this.state.rowSelection}
            onSelectionChanged={this.__onSelectionChanged}
            frameworkComponents={this.frameworkComponents}
            pagination={true}
            onCellClicked={this.__onCellClicked.bind(this)}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            suppressPaginationPanel={true}
            modules={AllModules}>
          </AgGridReact>
        </div>
        <RapidGridPagination gridId={gridId} onPageChange={this.__onGridPaginationClick}/>
      </div>
    );
  }
}
export default connect(null, {
  setPagination,
  setSelectedRowData,
  removeRapidGridData,
  questionSummary
})(RapidGrid);
