import CONFIG from '../config';
import Noty from 'noty';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
const Encryptor = require('simple-encryptor');

export const showNotification = (message, notificationType) => {
  if(message && notificationType) {
    new Noty({
      text: message,
      timeout: 2000,
      layout: "topRight",
      type: notificationType,
      theme: "mint",
    }).on('afterShow', function() {
      //clearNotification();
    }).show();
  }
};

export const getDateFromTimeStamp = timeStamp => {
  const getDate = new Date(timeStamp);
  return (getDate.getDate() + '/' + Number(getDate.getMonth() + 1) + '/' +  getDate.getFullYear());
};

export const autoDownloadFileFromUrl = (url, fileName) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
};

export const encryptData = data => {
  try {
    const cryptr = new Encryptor(CONFIG['ENCRYPTION']);
    return cryptr.encrypt(data);
  } catch (exception) {
    throw new Error(exception.message);
  }
};

export const decryptData = hash => {
  try {
    const cryptr = new Encryptor(CONFIG['ENCRYPTION']);
    return cryptr.decrypt(hash);
  } catch (exception) {
    throw new Error(exception.message);
  }
};

export const millisToMinutesAndSeconds = millis => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
};

export const nextChar = (character, i) => String.fromCharCode(character.charCodeAt(0) + i);

export const randomString = length => {
  let result = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const encryptNumber = number => {
  const encryptNum = number.toString().split('').reverse().join('');
  return `${randomString(3)}${encryptNum}${randomString(4)}`;
};

export const decryptNumber = encryptData => {
  let decryptNum = encryptData.slice(3, encryptData.length - 4);
  return decryptNum.split('').reverse().join('');
};
