import React, {useCallback, useState} from 'react';
import {Input} from 'semantic-ui-react';
import {showNotification} from "../utils";
import debounce from 'lodash/debounce';

let currentSearchText = '';
function SearchInput({ placeholder, onSearchSubmit, ...restProps }) {

  const handleSearch = (searchedValue) => {
    searchedValue = searchedValue.trim();
    if (currentSearchText === '' && searchedValue === ''){
      showNotification( 'Please enter search field',  'error');
    } else if (searchedValue !== '' && searchedValue.length < 3) {
      showNotification('Please enter at least 3 characters',  'warning');
    } else {
      currentSearchText = searchedValue;
      onSearchSubmit(searchedValue);
    }
  };
  const debouncedFunction = useDebouncedCallback(handleSearch, 1000);
  const [searchedValue, setSearchValue] = useState("");

  return (
    <Input
      icon={{ name: 'search', link: true}}
      className="search form"
      input={{ className: 'prompt fullRounded' }}
      value={searchedValue}
      onChange={(e) => {
          setSearchValue(e.target.value);
          debouncedFunction(e.target.value);
        }
      }
      placeholder={placeholder || 'Search here...'}
      { ...restProps}
    />
  )
}

function useDebouncedCallback(callback, delay) {
  return useCallback(debounce(callback, delay), []);
}

export default SearchInput;
