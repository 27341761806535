import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import {encryptData, sessionStore} from "../index";

let _store = null;
export function getStore() {
  return _store;
}
export function configureStore(rootReducer, initialState) {
  const composeEnhancers = composeWithDevTools({});
  _store = createStore(rootReducer, initialState,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  );
  _store.subscribe(() => {
    const examBoardState = _store.getState().examBoard;
    examBoardState && sessionStore.set('examData', encryptData(JSON.stringify(examBoardState)))
  });
  return _store;
}
