import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { configureStore } from './shared/redux/store';
import App from './student/App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less';
import { Provider as ReduxProvider } from 'react-redux';
import { ErrorBoundary } from './shared/components';
import rootReducer from './student/redux/reducers';

const store = configureStore(rootReducer);

ReactDOM.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <App/>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

