import React  from 'react';
import {
  Dimmer,
  Loader
} from 'semantic-ui-react'
import { connect } from 'react-redux';

const setLoadingClassToBody = () => {
  let element, name, arr;
  element = document.body;
  name = "loading";
  arr = element.className.split(" ");
  if (arr.indexOf(name) === -1) {
    element.className += " " + name;
  }
};
const removeLoadingClassToBody = () => {
  let element = document.body;
  element.className = element.className.replace(/\bloading\b/g, "");
};

export function Spinner({ showLoader }) {
  if (showLoader === false) {
    removeLoadingClassToBody();
  } else {
    setLoadingClassToBody();
  }
  return (
    <Dimmer className='loader-dimmer' active={showLoader !== false}>
       <Loader size="big">Loading</Loader>
    </Dimmer>
  )
}

const mapStateToProps = function(state) {
  return {
    showLoader: state.commonUI.showLoader
  }
};


export default connect(mapStateToProps)(Spinner);
