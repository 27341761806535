import React from 'react';
import {Icon} from 'semantic-ui-react';

class ActionRenderer extends React.Component{

  invokeParentMethod = async (action) => {
    await this.props.context.componentParent.onActionClick(this.props.node, action)
  };

  render() {
    return (
      <div className="IconLink">
        { this.props.context.componentParent.actionArray &&
        this.props.context.componentParent.actionArray.map((action, i) => {
          return (
            <button disabled={this.props.context.componentParent.props.actionDisabled} key={i} onClick={() => this.invokeParentMethod(action.action)} title={action.name}>
              <Icon name={action.icon} size='large' />
            </button>
          )})
        }
      </div>
    );
  }
}

export default ActionRenderer;
