import axios from "axios";
import get from 'lodash.get';
import  { getStore }  from "./redux/store";
import { hideLoader, showLoader } from './redux/actions/commonUIAction';
import {showNotification, sessionStore, localStore} from "./index";
import {checkLogin, logout} from './redux/actions/authAction';
import {removeExamData} from "../student/redux/actions/examBoardAction";
import CONFIG  from '../config';

const apiWithoutAuth = [
  '/api/v1/student/login',
  '/v1/admin/login',
  '/api/v1/student/sign-up'
];

class RapidHTTPClient{
  onGoingAPICount = 0;

  isLoaderStarted = false;

  async callAPI({ url, method = 'GET', useLoader = true, refreshTokenHeader = false, errorMessage, successMessage, data, contentType = 'application/json', query = {}, axiosConfig }) {
    const baseURL = CONFIG.API_URL || window.location.origin;
    try{
      this.handleLoader({increment: true, useLoader});
      let userData;
      if (global.APP_NAME === 'Admin') {
        userData = localStore.getJSON(global.APP_NAME) || {};
      } else if (global.APP_NAME === 'Student') {
        userData = sessionStore.getJSON(global.APP_NAME) || {};
      }
      const headers = { authorization: `Bearer ${userData.accessToken}`,
        'Content-Type': `${contentType}`};
      if (refreshTokenHeader) {
        headers['refreshToken'] = userData.refreshToken;
        headers['role'] = global.APP_NAME;
      }
      const returnData =  await axios({
        data,
        headers,
        url: `${baseURL}${url}`,
        method,
        params: query,
        // cancelToken: axiosConfig
      });

      if(returnData.data.status === 'success'){
        this.handleNotification({message: successMessage, notificationType: 'success'});
        return returnData.data.data;
      } else {
        throw returnData;
        // throw returnData && returnData.data && returnData.data.error;
      }
    } catch(e) {
      if (get(e, 'response.status') === 401 && !apiWithoutAuth.includes(url)) {
        if (url !== '/api/v1/check-login') {
          try {
            // try to renew the access token using refresh token
            await getStore().dispatch(checkLogin());
            // token renewed successfully, retry failed api
            return await this.callAPI(arguments[0]);
          } catch (e) {
            // Renew token failed, show notification (user will be redirected to login screen)
            this.handleNotification({ message: 'You have been logged out', notificationType: 'info' });
            throw e;
          }
        } else {
          throw e;
        }
      } else if(get(e, 'response.status') === 413) {
        this.handleNotification({ message: 'Request body size is too large', notificationType: 'error' });
        throw e;
      } else if (e && e.message === 'Network Error') {
        this.handleNotification({ message: 'Network connectivity lost', notificationType: 'error'});
        throw e;
      } else if (get(e, 'response.data.error.code') === '4010') {
        getStore().dispatch(logout());
        getStore().dispatch(removeExamData());
      }
      const errorResponse = e.response;
      const message = (errorResponse && errorResponse.data.error && errorResponse.data.error.message)
        || (e && e.message)
        || errorMessage
        || 'Unexpected error occurred.';
      this.handleNotification({message, notificationType: 'error'});
      throw e;
    } finally {
      this.handleLoader({increment: false, useLoader});
    }
  }

  handleNotification({message, notificationType = 'success'}){
    if(!message){
      return;
    }
    showNotification(message, notificationType);
  }

  handleLoader({ increment, useLoader }) {
    if(!useLoader){
      return;
    }
    if(increment){
      this.onGoingAPICount++;
    } else {
      this.onGoingAPICount--;
    }
    if (this.onGoingAPICount > 0 && !this.isLoaderStarted) {
      this.isLoaderStarted = true;
      getStore().dispatch(showLoader());
    } else if (this.onGoingAPICount <= 0 && this.isLoaderStarted) {
      this.isLoaderStarted = false;
      getStore().dispatch(hideLoader());
    }
  }
}
export const rapidHttpClient = new RapidHTTPClient();
