import React from 'react';
import { connect } from 'react-redux';
import { Pagination, Icon } from 'semantic-ui-react';
import get from "lodash.get";

function RapidGridPagination({ activePage, totalPages, onPageChange }) {
  return (
    <>
      {totalPages > 1 && <Pagination
        onPageChange={onPageChange}
        activePage={activePage || 1}
        totalPages={totalPages || 1}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='long arrow alternate left' />, icon: true }}
        nextItem={{ content: <Icon name='long arrow alternate right' />, icon: true}}
      />}
    </>
  );
}

const mapStatToProps = (state, ownProps) => {

  return {
    activePage: get(state, `rapidGrid.${ownProps.gridId}.pagination.activePage`, 0),
    totalPages: get(state, `rapidGrid.${ownProps.gridId}.pagination.totalPages`, 0),
  };
};
export default connect(mapStatToProps)(RapidGridPagination);
