import React, {Component} from 'react';

class CustomTooltip extends Component{
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }
  render() {
    const valueToDisplay = this.props.value ? this.props.value : '- Missing -';
    return (
      <div>
        {valueToDisplay}
      </div>
    )
  }
}

export default CustomTooltip;
