import shared from '../shared/lang';
import exam from './modules/exam/lang';

export const lang = {
  "en": {
    "auth": shared['en']['auth'],
    "exam": exam['en'],
    "validators": shared['en']['validators']
  }
};


