import React from 'react';
import t from 'prop-types';
import RouteContext from './LanguageContext';

class LanguageProvider extends React.Component {
  static propTypes = {
    children: t.node.isRequired,
  };

  render() {
    return (
      <RouteContext.Provider>
        {this.props.children}
      </RouteContext.Provider>
    );
  }
}

export default LanguageProvider;
