import React, { useState, useEffect } from 'react';
import 'semantic-ui-less/semantic.less';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RenderRoutes from './core/RenderRoutes';
import LanguageProvider from '../shared/context/LanguageProvider';
import { Spinner } from '../shared/components/Spinner';
import { connect } from 'react-redux';
import { checkLogin } from '../shared/redux/actions/authAction';
import {I18nextProvider} from "react-i18next";
import {i18nInstance} from "../config/language";
import {lang} from "./lang";

global.APP_NAME = 'Student';

const baseRoutes = [
  {
    path: '/exam',
    component: React.lazy(() => import(/* webpackChunkName: "student-exam-module" */'./modules/exam/ExamRoutes')),
  }, {
    path: '/',
    component: React.lazy(() => import(/* webpackChunkName: "student-auth-module" */'./modules/auth/AuthRoutes')),
  }
];


/**
 * @return {null}
 */
function App({ isUserLoggedIn, checkLogin }) {
  const [checkAuth, setCheckAuth] = useState(false);

  const setCheckAuthData = () => {
    i18nInstance(lang);
    if (!isUserLoggedIn) {
      (async () => {
        try {
          await checkLogin();
        } catch (e) {}
        setCheckAuth(true);
      })();
    } else {
      setCheckAuth(true);
    }
  };

  useEffect(setCheckAuthData, []);

  return (
    checkAuth ? (
      <React.Suspense fallback={<Spinner/>}>
        <Router>
          <Switch>
            <I18nextProvider i18n={global.i18n}>
              <LanguageProvider>
                <RenderRoutes routes={baseRoutes}/>
              </LanguageProvider>
            </I18nextProvider>
          </Switch>
        </Router>
      </React.Suspense>
    ) : <Spinner />
  );
}

const mapStateToProps = function (state) {
  return {
    isUserLoggedIn: !!state.auth.user,
  };
};

export default connect(mapStateToProps, {
  checkLogin
})(App);

