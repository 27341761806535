export class localStore {

  /**
   * @method set
   * @description function to set the data in localStorage
   */
  static set(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * @method get
   * @description function to get the item from the localStorage
   */
  static get(key) {
    return localStorage.getItem(key);
  }

  /**
   * @method getJSON
   * @description function to get the json value from the localStorage
   */
  static getJSON(key) {
    let returnValue = {};
    try {
      returnValue = JSON.parse(localStorage.getItem(key));
    } catch (e) {}
    return returnValue || {};
  }

  /**
   * @method getJSON
   * @description function to get the json value from the localStorage
   */
  static setJSON(key, data) {
    const jsonStr = JSON.stringify(data);
    return localStorage.setItem(key, jsonStr);
  }

  /**
   * @method remove
   * @description function to remove the localStorage item
   */
  static remove(key) {
    return localStorage.removeItem(key);
  }

  /**
   * @method clear
   * @description function to clear localstorage
   */
  static clear() {
    return localStorage.clear();
  }
}

export default localStore
