import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Header, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react'
import { getFormValues } from 'redux-form/es';
import get from 'lodash.get';
import QuestionOptions from './QuestionOptions';
import PointOfContactDetail from './PointOfContactDetail';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import { Link } from 'react-router-dom';

const ObjectID = require('bson-objectid');

const renderCheckbox = ({ input, isRequired, label, className, hint, meta: { error, touched } }) => {
  return (
    <Fragment>
      <Form.Checkbox
        className={className}
        checked={!!input.value}
        name={input.name}
        label={label}
        required={isRequired}
        error={touched && error}
        onChange={(e, { checked }) => input.onChange(checked)}
      />
      {hint && <h6 className="ui grey header tiny mt0">{hint}</h6>  }

    </Fragment>
  );
};

const RenderRichTextEditor = (field) => {
  return (
    <Field {...field} component={RichTextEditor} />
  );
};

const renderTextArea = ({ input, isRequired, label, placeholder, autoFocus, meta: { error, touched } }) => {
  return (
    <Fragment>
      <Form.TextArea
        {...input}
        autoFocus={autoFocus}
        label={label}
        required={isRequired}
        error={touched && error}
        placeholder={placeholder}
      />
    </Fragment>
  );
};

const renderInputField = ({ input, isRequired, label, placeholder, type, autoFocus, meta: { error, touched } }) => {
  return (
    <Fragment>
      <Form.Input
        {...input}
        autoFocus={autoFocus}
        type={type}
        label={label}
        required={isRequired}
        error={touched && error}
        placeholder={placeholder}
        onChange={input.onChange}/>
    </Fragment>
  );
};


const renderNumberField = ({ input, isRequired, isReadOnly, className, label, placeholder, onFocusOut, setValue, hint, autoFocus, formValues, meta: { error, touched }, min }) => {
  const value = input.value;
  input = min ? {...input, min} : input;
  return (
    <div className='field'>
      <Form.Input
        {...input}
        autoFocus={autoFocus}
        className={className}
        label={label}
        type={'number'}
        readOnly={isReadOnly}
        value={typeof setValue === 'function' ? setValue(formValues) : value}
        required={isRequired}
        error={touched && error}
        placeholder={placeholder}
        onChange={input.onChange}
        onBlur={typeof onFocusOut === 'function' ? () => onFocusOut(formValues) : input.onBlur}/>
      {hint && typeof hint === 'function' ? hint(formValues) : hint}
    </div>
  );
};
const renderSelect = ({ label, input, isRequired, options, placeholder, meta: { error, touched }, extraProps }) => {
  const classNames = 'field ' + (isRequired ? 'required ' : '') + ((error && touched) ? 'error' : '');
  return (
    <Fragment>
      <div className={classNames}>
        <label>{label}</label>
        <div className="ui input">
          <Dropdown
            selectOnBlur={false}
            placeholder={placeholder}
            name={input.name}
            value={input.value}
            options={options}
            onChange={(e, { value }) => input.onChange(value)}
            fluid
            selection
            search
            {...extraProps}
          />
        </div>
        {touched && error && <div className="ui pointing above prompt label">{error}</div> }
      </div>
    </Fragment>
  );
};

const renderRadio = ({ input, label, isRequired, radioValue, meta: { error, touched } }) => {
  return (
    <Fragment>
      <Form.Radio
        checked={input.value === radioValue}
        label={label}
        name={input.name}
        required={isRequired}
        error={touched && error}
        onChange={(e, { checked }) => input.onChange(radioValue)}
      />
    </Fragment>
  );
};

const customHeader = label => <Header size='small' className='mb30' dividing>{label}</Header>;

const renderFormField = (field, formValues, index, fieldIndex) => {
  let compType = selectComponent(field.type);
  field = field.type === 'number' ? { ...field, parse: value => value.trim() === '' ? '' : Number(value) } : field;

  if (field.type === 'textField' && field.richTextEditor) {
    return <RenderRichTextEditor key={index} { ...field} />;
  } else {
    return <Field key={index}
      autoFocus={(!fieldIndex && index === 0) || (field.autoFocus)}
      component={compType}
      formValues={formValues}
      {...field}
    />;
  }

};

const renderFormGroup = (fields, formValues, fieldIndex) => {
  return (
    <Fragment key={fieldIndex}> {fields.label && customHeader(fields.label)}
      <Form.Group widths={fields.width}>
        {fields.fields.map((field, index) => renderFormField(field, formValues, index, fieldIndex))}
      </Form.Group>
    </Fragment>
  );
};

const selectComponent = componentType => {
  let compType = '';
  switch (componentType) {
    case 'checkbox':
      compType = renderCheckbox;
      break;
    case 'text':
    case 'password':
      compType = renderInputField;
      break;
    case 'textField':
      compType = renderTextArea;
      break;
    case 'select':
      compType = renderSelect;
      break;
    case 'radio':
      compType = renderRadio;
      break;
    case 'number':
      compType = renderNumberField;
      break;
    case 'formGroup':
      compType = renderFormGroup;
      break;
    case 'questionOptions':
      compType = QuestionOptions;
      break;
    case 'pointOfContactPersonDetail':
      compType = PointOfContactDetail;
      break;
    default:
      compType = '';
      break;
  }
  return compType;
};

class FormBuilderComponent extends React.Component {

  handleSubmit = (isSave) => {
    return this.props.handleSubmit(() => {
        this.props.onSubmit(this.props.formValues, this.props.reset, isSave);
      }
    );
  };

  render() {
    const { fields, submitText, saveAndAddNew, formValues, cancel, isUpdate, onClose, showCancelButton, showTotalMarks, totalMarks, linkPath, linkTitle, checkBoxLabel } = this.props;
    const fieldWithoutQuestionOptions = fields.filter((field) => {
      return field.type !== 'questionOptions';
    });
    const questionOptionsField = fields.find((field) => {
      return field.type === 'questionOptions';
    });
    return (
      <Fragment>
        <Form noValidate onSubmit={this.handleSubmit(true)}>
          <div className="ui grid scrolling content">
            <div className={questionOptionsField ? 'eight wide column' : 'sixteen wide column'}>
              {
                fieldWithoutQuestionOptions.map((field, index) => {
                  if (field.type === 'formGroup') {
                    return renderFormGroup(field, formValues, index);
                  } else {
                    return renderFormField(field, formValues, index);
                  }
                })}
            </div>
            {questionOptionsField && (
              <div className={'eight wide column'}>
                <Field
                  key={fieldWithoutQuestionOptions.length}
                  component={QuestionOptions}
                  formValues={formValues}
                  {...questionOptionsField}
                />
              </div>
            )}
          </div>
          <div className={linkPath && linkTitle ? "mt30 d-flex align-item-center justify-space-between" : "mt30 d-flex align-item-center"}>
            <Button className="ui button primary tiny mr10">{submitText || 'Submit'}</Button>
            {linkPath && linkTitle ? <div className="signupText">
              <Link to={linkPath}>{linkTitle}</Link>
            </div> : null }
            {saveAndAddNew && !isUpdate ? <Button className="ui button basic grey tiny" type={'button'} onClick={this.handleSubmit(false)}>{saveAndAddNew}</Button> : null}
            {showCancelButton === false ? null : <Button className='textButton' type={'button'} onClick={onClose}>{cancel || 'Cancel'}</Button>}
            {showTotalMarks && <span className="OptionNo justify-self-end">{`Total Marks: ${totalMarks}`}</span>}
            {checkBoxLabel ? <Field component={renderCheckbox} name={checkBoxLabel} className={'justify-self-end d-flex'} label={checkBoxLabel} /> : null }
          </div>
        </Form>
      </Fragment>
    );
  }
}

const FormBuilderWrap = reduxForm({ form: 'formBuilder', enableReinitialize: true })(FormBuilderComponent);

const mapStateToProps = (state, props) => {
  if (props && typeof (props.fieldValues) === 'string') {
    props.fieldValues = {};
    props.fieldValues.AnswerOption = [
      { 'Text': '', '_id': ObjectID()['str'], 'isSelected': false },
      { 'Text': '', '_id': ObjectID()['str'], 'isSelected': false },
      { 'Text': '', '_id': ObjectID()['str'], 'isSelected': false },
      { 'Text': '', '_id': ObjectID()['str'], 'isSelected': false }];
    props.fieldValues.POCDetails = [
      {
        '_id': ObjectID()['str'],
        'Name': '',
        'ContactNumber': '',
        'Designation': '',
        'Department': '',
        'EmailId': ''
      }
    ]
  }
  if (props.showTotalMarks) {
    const EasyQuestions = get(state, 'form.formBuilder.values.EasyQuestions');
    const MediumQuestions = get(state, 'form.formBuilder.values.MediumQuestions');
    const HardQuestions = get(state, 'form.formBuilder.values.HardQuestions');
    return {
      initialValues: props.fieldValues || {},
      formValues: getFormValues('formBuilder')(state),
      totalMarks: ((EasyQuestions || 0) + ((MediumQuestions || 0) * 2) + ((HardQuestions || 0) * 3))
    };
  }
  return {
    initialValues: props.fieldValues || {},
    formValues: getFormValues('formBuilder')(state)
  };
};

export const FormBuilder = connect(mapStateToProps)(FormBuilderWrap);

export { renderInputField, renderCheckbox };
