export const validators = {
  required: errMessage => value => value ? undefined : errMessage || global.i18n.t('validators.required'),
  string: errMessage => value => value && /[^a-z ,.'-]/i.test(value) ? errMessage ||  global.i18n.t('validators.string'): undefined,
  email: errMessage => value =>
    value && !/^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/i.test(value) ?
      errMessage || global.i18n.t('validators.email'): undefined,
  password: errMessage => value =>
    value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#@$&~]).{6,16}$/i.test(value) ?
      errMessage || global.i18n.t('validators.password') : undefined,
  confirmationPassword: errorMessage => (value, allValues) => {
    return value && allValues && allValues.Password === allValues.ConfirmPassword ? undefined : errorMessage || global.i18n.t('validators.confirmationPassword')},
  multipleEmails: errMessage => value => value &&
  !/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/i.test(value) ? errMessage
    || global.i18n.t('validators.multipleEmails'): undefined,
  totalTechnicalValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.LogicalQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.totalTechnicalValidation'): undefined },
  totalLogicalValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.TechnicalQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.totalLogicalValidation'): undefined },
  easyQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.MediumQuestions || 0) + (allValue.HardQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.easyQuestionsValidation'): undefined },
  mediumQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.EasyQuestions || 0) + (allValue.HardQuestions || 0)) > allValue.TotalQuestions ?
      errMsg ||  global.i18n.t('validators.mediumQuestionsValidation'): undefined },
  hardQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.MediumQuestions || 0) + (allValue.EasyQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.hardQuestionsValidation'): undefined },
  CommunicationQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.MarketingQuestions || 0) + (allValue.LogicalMarketingQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.saleQuestionsValidation'): undefined },
  MarketingQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.CommunicationQuestions || 0) + (allValue.LogicalMarketingQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.saleQuestionsValidation'): undefined },
  LogicalMarketingQuestionsValidation: errMsg => (value, allValue) => {
    return value && allValue && allValue.TotalQuestions && (value + (allValue.MarketingQuestions || 0) + (allValue.CommunicationQuestions || 0)) > allValue.TotalQuestions ?
      errMsg || global.i18n.t('validators.saleQuestionsValidation'): undefined },
  contactNumberValidation: errMessage => value =>
    value && !/^\d{5}([- ]*)\d{5,6}$/i.test(value) ?
      errMessage || global.i18n.t('validators.contactNumberValidation'): undefined,
  numberRangeValidation: (errMsg, min, max, fieldName) => (value) => {
    return (value || value === 0) && (value < min || value > max) ?
        (errMsg || ((min || min === 0) && max ? `${fieldName} should be between ${min} to ${max}` :
            (min || min === 0) && value < min ? `${fieldName} should be greater than ${min}` :
            max && value > max? `${fieldName} should be less than ${max}`:
        undefined)) : undefined
  },
  numberRequired: errMessage => value => !isNaN(value) ? undefined : errMessage || global.i18n.t('validators.required'),
};
