export const RAPID_GRID_ACTIONS = {
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  SET_PAGINATION_STATE: 'SET_PAGINATION_STATE',
  REMOVE_GRID_DATA: 'REMOVE_GRID_DATA',
};

export const setSelectedRowData = (gridId, selectedRows) => async (dispatch) =>{
  dispatch({
    type: RAPID_GRID_ACTIONS.SET_SELECTED_ROWS,
    payload: { gridId, selectedRows }
  });
};


export const setPagination = (gridId, pagination) => async (dispatch) =>{
  dispatch({
    type: RAPID_GRID_ACTIONS.SET_PAGINATION_STATE,
    payload: { gridId, pagination }
  });
};

export const removeRapidGridData = (gridId) => async (dispatch) => {
  dispatch ({
    type: RAPID_GRID_ACTIONS.REMOVE_GRID_DATA,
    payload: gridId
  });
};

