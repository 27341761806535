import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import commonUIReducer  from "../../../shared/redux/reducers/commonUIReducer";
import authReducer  from "../../../shared/redux/reducers/authReducer";
import examBoardReducer from "./examBoardReducer";

const rootReducer = combineReducers({
  commonUI: commonUIReducer,
  auth: authReducer,
  form: formReducer,
  examBoard: examBoardReducer
});

export default rootReducer;
