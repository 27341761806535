import { rapidHttpClient } from '../../../shared';

export const QUESTION_SUMMARY_ACTION = {
  QUESTION_ADDED: 'QUESTION_ADDED',
  SET_QUESTION_SUMMARY: 'SET_QUESTION_SUMMARY',
};

export const questionSummary = (url) => async (dispatch) => {
  try {
    const responseData = await rapidHttpClient.callAPI({
      url: url,
      method:'GET'
    });
    dispatch ({
      type: QUESTION_SUMMARY_ACTION.SET_QUESTION_SUMMARY,
      payload: {  ...responseData }
    });
  } catch (e) {}
};

export const questionAdded = (question) => async (dispatch) => {
  dispatch ({
    type: QUESTION_SUMMARY_ACTION.QUESTION_ADDED,
    payload: question
  });
};

