import i18next from "i18next";

export function i18nInstance(lang) {
  const options = {
    debug: true,
    resources: {
      en: {
        common: lang.en,
      },
    },
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'lang.js.json'
    },
  };
  global.i18n = i18next.createInstance(options);
  global.i18n.init();
}
