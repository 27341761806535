import { rapidHttpClient } from '../../index';

export const AUTH_ACTIONS = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER'
};

export const checkLogin = () => async (dispatch) => {
  try {
    const userData = await rapidHttpClient.callAPI({ url:`/api/v1/check-login`, refreshTokenHeader: true });
    dispatch ({
      type: AUTH_ACTIONS.SET_USER,
      payload: userData
    });
  } catch (e) {
    removeAuthData(dispatch);
    throw e;
  }
};

export const logout = () => async (dispatch) => {
  removeAuthData(dispatch);
};

export const doAdminLogin = (data) => async (dispatch) => {
  try {
    const userData = await rapidHttpClient.callAPI({ url:'/api/v1/admin/login', data, method:'POST' });
    dispatch ({
      type: AUTH_ACTIONS.SET_USER,
      payload: userData
    });
  } catch (e) {}
};

export const doStudentSignUp = (data, history) => async dispatch => {
  dispatch({
    type: AUTH_ACTIONS.SET_USER,
    payload: data
  });
  history.push('instruction');
};

export const doStudentLogin = (data) => async (dispatch) => {
  try {
    const userData = await rapidHttpClient.callAPI({ url:'/api/v1/student/login', data, method:'POST' });
    dispatch ({
      type: AUTH_ACTIONS.SET_USER,
      payload: userData
    });
  } catch (e) {}
};

function removeAuthData(dispatch) {
  dispatch ({
    type: AUTH_ACTIONS.REMOVE_USER
  });
}
