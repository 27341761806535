import get from 'lodash.get';
import {decryptData, encryptData, rapidHttpClient, encryptNumber, decryptNumber, sessionStore } from '../../../shared';
let timerInterval;
let syncInterval;

export const EXAM_BOARD_ACTIONS = {
  LOAD_EXAM_BOARD_DATA: 'LOAD_EXAM_BOARD_DATA',
  LOAD_QUESTION_DATA_BY_INDEX:'LOAD_QUESTION_DATA_BY_INDEX',
  SET_QUESTION_STATUS: 'SET_QUESTION_STATUS',
  SET_ANSWER_QUESTION: 'SET_ANSWER_QUESTION',
  REMOVE_EXAM_DATA: 'REMOVE_EXAM_DATA'
};

export const loadExamBoard = (studentId, examId, history) => async dispatch => {
  try {
    let responseData;
    const examDataInLocalStore = sessionStore.get('examData');
    if (examDataInLocalStore) {
      responseData = examDataInLocalStore;
    } else {
      responseData = await rapidHttpClient.callAPI({
        url: `/api/v1/exam-progress/${studentId}/${examId}/start`,
        method: 'POST'
      });
      if (responseData && responseData.examCompleted) {
        history.push('finish');
      }
      sessionStore.set('examData', responseData);
    }
    const decryptedData = JSON.parse(await decryptData(responseData));
    sessionStore.set('examRemainingTime', encryptNumber(decryptedData.RemainingTime));
    history.push('board');
    dispatch ({
      type: EXAM_BOARD_ACTIONS.LOAD_EXAM_BOARD_DATA,
      payload: { ...decryptedData }
    });
  } catch (e) { }
};

export const loadDataFromLocalStorage = history => async dispatch => {
  const localStoreData = sessionStore.get('examData');
  if (!localStoreData) {
    return history.push('instruction');
  }
  let decryptString;
  try {
    decryptString = await decryptData(localStoreData);
    if (!decryptString || decryptString === {}) {
      history.push('instruction');
    }
  } catch (e) {
    history.push('instruction');
  }
  const decryptedData = JSON.parse(decryptString);
  dispatch ({
    type: EXAM_BOARD_ACTIONS.LOAD_EXAM_BOARD_DATA,
    payload: { ...decryptedData }
  })
};

export const setQuestionDataByIndex = (index, oldIndex) => async dispatch => {
  dispatch({
    type: EXAM_BOARD_ACTIONS.LOAD_QUESTION_DATA_BY_INDEX,
    payload: { index, oldIndex }
  })
};

export const setQuestionStatus = (index, IsMarked) => async dispatch => {
  dispatch({
    type: EXAM_BOARD_ACTIONS.SET_QUESTION_STATUS,
    payload: { index, IsMarked }
  })
};

export const setAnswerQuestion = (id, index) => async dispatch => {
  dispatch({
    type: EXAM_BOARD_ACTIONS.SET_ANSWER_QUESTION,
    payload: { id, index }
  })
};

export const setRemainingTime = time => async dispatch => sessionStore.set('examRemainingTime', encryptNumber(time));

export const callSyncExam = (Loader = false, history) =>  async (dispatch, getState) => {
  const examBoardState = getState().examBoard;
  const time = sessionStore.get('examRemainingTime');
  if (examBoardState) {
    const examEncrypt = encryptData(JSON.stringify({ ...examBoardState, RemainingTime: decryptNumber(time) }));
    sessionStore.set('examData', examEncrypt);
    try {
      await rapidHttpClient.callAPI({
        url: `/api/v1/exam-progress/${examBoardState._id}/sync`,
        method: 'POST',
        useLoader: Loader,
        data: { data: examEncrypt }
      });
    } catch (e) {
      if (get(e, 'response.data.error.code') === '4009') {
        history.push('finish');
        dispatch ({
          type: EXAM_BOARD_ACTIONS.REMOVE_EXAM_DATA
        });
      }
    }
  }
};

export const finishExam = (IsTimedOut = false, history) => async (dispatch, getState) => {
  try {
    const state = getState().examBoard;
    const url = `/api/v1/student-results/${state.Exam}/${state.Student}/complete-exam`;
    await rapidHttpClient.callAPI({
      url: IsTimedOut ? url + '?IsTimedOut=true' : url,
      method: 'POST',
      useLoader: true
    });
    history.push('finish');
    dispatch ({
      type: EXAM_BOARD_ACTIONS.REMOVE_EXAM_DATA
    });
  } catch(e) {
    if (get(e, 'response.data.error.code') === '4009') {
      dispatch ({
        type: EXAM_BOARD_ACTIONS.REMOVE_EXAM_DATA
      });
      history.push('finish')
    } else {
      history.push('failedFinish');
    }
  }
};

export const removeExamData = () => async dispatch => {
  dispatch ({
    type: EXAM_BOARD_ACTIONS.REMOVE_EXAM_DATA
  });
};

export const setTimerInterval = (intervalFunction, delay) => async dispatch => timerInterval = setInterval(intervalFunction, delay);

export const clearTimerInterval = () => async dispatch => clearInterval(timerInterval);

export const setSyncInterval = (intervalFun, delay) => async dispatch => {
  if (!syncInterval) {
    syncInterval = setInterval(intervalFun, delay);
  }
};

export const clearSyncInterval = () => async dispatch => {
  clearInterval(syncInterval);
  syncInterval = undefined;
};
