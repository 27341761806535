import React from 'react';
import { FieldArray, Field } from 'redux-form';
import {renderCheckbox, renderInputField} from "./FormBuilder";

const ObjectID = require("bson-objectid/objectid");
class QuestionOptions extends React.Component {

  render() {
    return (
      <FieldArray name="AnswerOption" component={renderAnswerOptions}/>
    )
  }
}
const required = value => (value || typeof value === 'number' ? undefined : 'Answer Text necessary');
const renderAnswerOptions = ({ fields }) => {
  return(
    <div>
      <h6>Note - Check correct answers</h6>
      <ol className="AnswerOption">
        {fields.map((value, index) =>

          <li key={index} className={(fields.get(index).isSelected && fields.get(index).Text) ? 'checkedAnswer d-flex align-item-center justify-space-between1 pr20' : 'd-flex align-item-center justify-space-between1 pr20'}>
            <span className="OptionNo">{String.fromCharCode(index+65)}</span>
            <Field name={`${value}.Text`} type="text" validate={[required]}
                   component={renderInputField}/>
            <Field name={`${value}.isSelected`} type="checkbox" component={renderCheckbox}/>
            {index > 3 ? <button type="button"
                                 onClick={() => fields.remove(index)}> <i aria-hidden="true" className="delete small icon"></i> </button> : null}
          </li>
        )}
        <div>
          {fields.length < 6 ? <button className="textButton mini" type="button" id={'AddAnswer'} onClick={() =>
          {fields.push({'_id': ObjectID()['str']})}
          }><i aria-hidden="true" className="plus circle icon"></i>Add Answer</button> : null }
        </div>
      </ol>
    </div>
)};

export default QuestionOptions;
