const environment = {
  local: {
  API_URL: 'http://localhost:9001',
  BASE_PATH: '/',
  ENCRYPTION: 'qPfuNRiNHNzzGXZa'
  },
  dev :{
  API_URL: 'https://rapidhunt.app',
  BASE_PATH: '/admin',
  ENCRYPTION: 'RPK89FIUYZjwmtNa'
  },
  localProduction: {
    API_URL: '',
    BASE_PATH: '/admin',
    ENCRYPTION: 'lSOwNRiNHNkiUXZa'
  }
};

const config = environment[process.env.REACT_APP_STAGE] ||  environment.local;

export default {
  // Add common config values here
  ...config
};
