export const UI_ACTIONS = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER'
};

export function showLoader() {
  return {
    type: UI_ACTIONS.SHOW_LOADER
  };
}

export function hideLoader() {
  return {
    type: UI_ACTIONS.HIDE_LOADER
  };
}

