import { AUTH_ACTIONS } from "../actions/authAction";
import { sessionStore, localStore } from '../../index';

const initialAppState = {
  user: null
};

const authReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.SET_USER:
      if (action.payload && action.payload.Role && action.payload.Role === 'Student') {
        sessionStore.setJSON([action.payload.Role], action.payload);
      } else if (action.payload && action.payload.Role && action.payload.Role === 'Admin') {
        localStore.setJSON([action.payload.Role], action.payload);
      }
      return { ...state, user: action.payload };
    case AUTH_ACTIONS.REMOVE_USER:
      if (global.APP_NAME === 'Admin') {
        localStore.remove(global.APP_NAME)
      } else if (global.APP_NAME === 'Student') {
        sessionStore.remove(global.APP_NAME);
      }
      return { ...state, user: null };
    default:
      return state;
  }
};
export default authReducer;
