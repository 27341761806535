export const AG_GRID = {
  PAGE_SIZE: 20
};

export const COLLEGE_GRID = {
  API: '/api/v1/colleges',
  ID: 'college'
};

export const QUESTION_SET_GRID = {
  API: '/api/v1/question-sets',
  ID: 'questionSets'
};

export const QUESTION_SET_QUESTIONS_GRID = {
  API: '/api/v1/question-sets',
  ID: 'questionSetsQuestions'
};

export const SWAP_QUESTIONS_GRID = {
  API: '/api/v1/questions',
  ID: 'swapQuestions'
};

export const QUESTION_GRID = {
  API: `/api/v1/questions`,
  ID: `questions`
};

export const QUESTION_SUMMARY = {
  API: `/api/v1/questions/getQuestionListSummary`
};
