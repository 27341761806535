import React from 'react';
import { FieldArray, Field } from 'redux-form';
import { renderInputField } from "./FormBuilder";
import { Form } from 'semantic-ui-react';
import {validators} from "./fbvalidators";

const ObjectID = require("bson-objectid/objectid");
const nameValidator = [validators.required('POC Name required')];
const contactNumberValidator = [validators.required('Contact Number required'), validators.contactNumberValidation('Please provide valid contact number')];
const emailValidator = [validators.required('Email Id required'), validators.email('Please provide valid email address')];
class PointOfContactDetail extends React.Component {

  render() {
    return (
      <FieldArray name="POCDetails" component={renderPointOfContactDetailComponent}/>
    )
  }
}
const renderPointOfContactDetailComponent = ({ fields }) => {
  return(
    <div className="POC-form">
      <ul className="">
        {fields.map((value, index) =>

          <li key={index}>
            <div className="pocFormHeading d-flex justify-space-between align-item-center">
              <h5>{`POC Detail ${index + 1}` }</h5>
              <button className="removeButton" type="button">
              {index > 0 ? <i  onClick={() => fields.remove(index)} aria-hidden="true" className="remove circle icon"/>: null}
            </button>
            </div>
            <Form.Group className="equal width">
              <Field label='Name' name={`${value}.Name`} type="text" validate={nameValidator} isRequired={true} component={renderInputField}/>
              <Field className="field required" label='Contact Number' name={`${value}.ContactNumber`} validate={contactNumberValidator} type="text" isRequired={true} component={renderInputField}/>
            </Form.Group>
            <Form.Group className="equal width">
              <Field className="field required" label='Designation' name={`${value}.Designation`} type="text"
                     component={renderInputField}/>
              <Field className="field required" label='Department' name={`${value}.Department`} type="text"
                     component={renderInputField}/>
            </Form.Group>
            <Field label='Email Id' name={`${value}.EmailId`} type="text" isRequired={true} validate={emailValidator} component={renderInputField}/>
          </li>
        )}
        <div className="addForm">
          {fields.length < 5 ? <button className="textButton mini" type="button" id={'AddAnswer'} onClick={() =>
          {fields.push({'_id': ObjectID()['str']});}
          }><i aria-hidden="true" className="plus circle icon"/>Add POC Details</button> : null }
        </div>
      </ul>
    </div>
  )};

export default PointOfContactDetail;
